import React from 'react'
import CV from '../../assets/My_Resume.pdf'

/*assuming this is for the two buttons at the top for lets talk and 
download resume*/
const CTA = () => {
  return (
    <div className = 'cta'>
        <a href = {CV} download className='btn'> Download CV</a>
        <a href="#contact" className='btn btn-primary'> Let's Talk</a>
    </div> 
  )
}

export default CTA