import React from 'react'
import './testimonials.css'
import HailMary from '../../assets/project_hail_mary.png'

// import Swiper core and required modules
import { Pagination } from 'swiper/modules';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

const data = [
  {
    avatar: HailMary,
    name: 'Project Hail Mary',
    review: 'blah  wee balh'
  },
  {
    avatar: HailMary,
    name: 'Project Hail Mary',
    review: 'blah blah balh'
  },
  {
    avatar: HailMary,
    name: 'Project Hail Mary',
    review: 'blah blah balh'
  },

]

const Testimonials = () => {
  return (
    <section id='testimonials'>
      <h5>(Basically a random combination of books and thought provoking videos)</h5>
      <h2>Exciting recent findings I want to share!</h2>

      <Swiper className="container testimonials__container"
       modules={[Pagination]}
       spaceBetween={40}
       slidesPerView={1}
       pagination={{ clickable: true }}
       >
         {/*the key given here is the unique iterable variable
            that the map function will go through and it assigns a
            unique ID to each item in the data array above*/}
        {
          data.map(({ avatar, name, review }, index) => {
           
            
            return (
              <SwiperSlide key={index} className="testimonial">
                <div className="client__avatar">
                  <img src={avatar} alt=''/>
                </div>
                <h5 className='clinet__name'>{name}</h5>
                <small className='clinet__review'>{review}.
                </small>
              </SwiperSlide>
            )
          })
        }

      </Swiper>
    </section>
  )
}


export default Testimonials