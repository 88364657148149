import React from 'react'
import {BsLinkedin} from 'react-icons/bs'
import {FaGithub} from 'react-icons/fa'
import { BsTwitterX } from "react-icons/bs";

const Header_Socials = () => {
  return (
    <div className = 'header__socials'>
        <a href= "https://linkedin.com" target="_blank"><BsLinkedin/></a>
        <a href= "https://github.com" target="_blank"><FaGithub/></a>
        <a href= "https://x.com" target="_blank"><BsTwitterX/></a>
    </div>
  )
}

export default Header_Socials