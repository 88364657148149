import React from 'react'
import './header.css'
import CTA from './CTA.jsx'
import ME from '../../assets/nik_snowboarding.JPG'
import HeaderSocial from './Header_Socials.jsx'

 //this is where my image class gets defined as .me in line 18*/
const Header = () => {
  return (
    <header>
      <div className="container header__container">
        <h5>Hello I'm</h5>
        <h1>Nikhil Deshpande</h1>
        <h5 className="text-light">Explorer and Problem Solver(But Financial Analyst by Job Title)</h5>
        <CTA />
        <HeaderSocial />

        <div className="me">
          {/*PICTURE ONLY WORKS IF ITS A SQUARE PIC*/}
          <img src={ME} alt="me" />
        </div>
        {/*<a href="#contact" className='scroll__down'> Scroll Down </a>*/}
      </div>
    </header>
  )
}

export default Header