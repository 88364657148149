import React from 'react'
import './footer.css'
import { SiFacebook } from "react-icons/si";
import { RiTwitterXLine } from "react-icons/ri";
import { PiInstagramLogoDuotone } from "react-icons/pi";

const Footer = () => {
  return (
    <footer>
      <a href="#" className = 'footer__logo'>Nikhil Deshpande</a>
    <ul className='permalinks'>
      <li><a href="#">Home</a></li>
      <li><a href="#about">About</a></li>
      <li><a href="#experience">Experience</a></li>
      <li><a href="#portfolio">Portfolio</a></li>
      <li><a href="#testimonials">Testimonials</a></li>
      <li><a href="contact">Contact</a></li>
    </ul>
    

    <div className="footer__socials">
      <a href="https://facebook.com"><SiFacebook/></a>
      <a href="https://instagram.com">< PiInstagramLogoDuotone/></a>
      <a href="https://x.com"><RiTwitterXLine /></a>
    </div>

{/*this is the copyright section!*/}
    <div className="footer__copyright">
      <small>&copy; ND Studios. All rights reserved</small>
    </div>
    </footer>
  )
}

export default Footer