import React from 'react'
import './about.css'
import ME from '../../assets/nikgradshot.JPG'
import { PiCertificateFill } from "react-icons/pi";
import { TbBlockquote } from "react-icons/tb";
import { MdSportsBasketball } from "react-icons/md";
/*each of these articles are the content cards below in the 
about__cards div*/
const About = () => {
  return (
    <section id = 'about'>
      <h5>Get To Know</h5>
      <h2>About Me</h2>

      <div className ="container about__container">
        <div className ="about__me">
          <div className="about__me-image">
            {/*PICTURE ONLY WORKS IF ITS A SQUARE PIC*/}
            <img src={ME} alt="About Image" />
          </div>
        </div>

        <div className="about__content">
          <div className = "about__cards">
            <article className = 'about__card'>
              <PiCertificateFill classname = 'about__icon'/>
              <h5>Experience</h5>
              <small>~1 Year(And Growing!)</small>
            </article>
            <article className = 'about__card'>
              <TbBlockquote classname = 'about__icon'/>
              <h5>Motto/Quote</h5>
              <small>~will eventually find some inspiration thing to put in here</small>
            </article>
            <article className = 'about__card'>
              <MdSportsBasketball classname = 'about__icon'/>
              <h5>Interest and Hobbies</h5>
              <small>Basketball, Soccer, Fitness Reading, doom scrolling on twitter...</small>
            </article>
          </div>
          <p>
            Lorem ipsum dolor sit, amet consectetur adipisicing elit. Laboriosam, obcaecati corrupti. 
            Corporis, odit nisi? Officia, doloribus id, molestiae ducimus voluptatum saepe nostrum iusto, dolorum magnam aspernatur 
            veniam perspiciatis porro dolore.
          </p>

         
        </div>
      </div>
    </section>
  )
}

export default About