import React from 'react'
import './portfolio.css'
import amigos from '../../assets/amigos.png'
import anteater from '../../assets/anteater.png'
import rando from '../../assets/rando.png'

/* this should organize the data for each card in my portfolio item
very simply for the future*/
const data = [
  {
    id: 1,
    image: amigos,
    title: 'Wellness App',
    github: 'https://github.com',
    /*i put some random youtube video here basically*/
    demo: 'https://www.youtube.com/watch?v=BHY0FxzoKZE'
  },
  {
    id: 2,
    image: rando,
    title: 'me chilling',
    github: 'https://github.com',
    /*i put some random youtube video here basically*/
    demo: 'https://www.youtube.com/watch?v=5MuIMqhT8DM'
  },
  {
    id: 3,
    image: anteater,
    title: 'zot zot zot',
    github: 'https://github.com',
    /*i put some random youtube video here basically*/
    demo: 'https://www.youtube.com/watch?v=5MuIMqhT8DM'
  }
]

const Portfolio = () => {
  return (
    <section id='Portfolio'>
      <h5>My Recent Work</h5>
      <h2>Portfolio</h2>
      {/* this is going to be hard coded stuff apparently */}
      <div className="container portfolio__container">
        {/*this is javascript mode apparently when the brackets are there*/}
        {/*this function maps the data from the data array made above,
        the {} brackets allow for the variable declared in the map function below
        to be inputted in different spots in the html. This way, next time I 
        have to update anything I don't have to modify stuff here, just the input
        above.*/}
        {
          data.map(({ id, image, title, github, demo }) => {
            return (
              <article key ={id} className = 'portfolio__item' >
                <div className="portfolio__item-image">
                  <img src={image} alt={title} />
                </div>
                <h3>{title}</h3>
                <div className='portfolio__item-cta'>
                  <a href={github} className='btn' target='_blank'>Github</a>
                  <a href={demo} className='btn' target='_blank'>Live Demo</a>
                </div>
              </article>
            )
          })
        }
    </div>
    </section >
  )
}

export default Portfolio

/* this is GPT Produced
const Testimonials = () => {
  const swiperContainerRef = useRef(null);

  useEffect(() => {
    if (swiperContainerRef.current) {
      const { Swiper } = require('swiper');
      new Swiper(swiperContainerRef.current, {
        
        slidesPerView: 1,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
    }});
      
    }
  }, []);

  return (
    <section id='testimonials'>
      <h5>(Basically a random combination of books and thought-provoking videos)</h5>
      <h2>Exciting recent findings I want to share!</h2>

      <div ref={swiperContainerRef} className="swiper-container">
        <div className="swiper-wrapper">
          {data.map(({ avatar, name, review }, index) => (
            <div key={index} className="swiper-slide testimonial">
              <div className="client__avatar">
                <img src={avatar} alt={name} />
              </div>
              <h5 className='client__name'>{name}</h5>
              <small className='client__review'>{review}</small>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}*/

/*this is tutorial produced
{const Testimonials = () => {
  return (
    <section id='testimonials'>
      <h5>(Basically a random combination of books and thought provoking videos)</h5>
      <h2>Exciting recent findings I want to share!</h2>

      <div className="container testimonials__container">
        {
          data.map(({ avatar, name, review }, index) => {

            {/*the key given here is the unique iterable variable
            that the map function will go through and it assigns a
            unique ID to each item in the data array above}

            return (
              <article key={index} className="testimonial">
                <div className="client__avatar">
                  <img src={avatar} />
                </div>
                <h5 className='clinet__name'>{name}</h5>
                <small className='clinet__review'>{review}.
                </small>
              </article>
            )
          })
        }

      </div>
    </section>
  )
}


export default Testimonials
*/