import React from 'react'
import './experience.css'
import { IoMdCheckmarkCircle } from "react-icons/io";


const Experience = () => {
  return (
    <section id='experience'>
      <h5>What Skills I have</h5>
      <h2>My Experience</h2>

      {/* this is the overall class containing both experience cards.
additionally, this 'container experience__container creates the
classname that is referred to in the css file for formatting it into
cards*/}
      <div className="container experience__container">
        {/* this is the content of the software development card */}
        <div className="experience_software_dev">
          <h3>Software Development</h3>
          <div className="experience__content">
            <article className='experience__details'>
              <IoMdCheckmarkCircle className='experience__details-icon' />
              <div>
                <h4>Python</h4>
                <small className='text-light'>Experienced</small>
              </div>
            </article>
            <article className='experience__details'>
              <IoMdCheckmarkCircle className='experience__details-icon'/>
              <div>
                <h4>SQL</h4>
                <small className='text-light'>Experienced</small>
              </div>
            </article>
            <article className='experience__details'>
              <IoMdCheckmarkCircle className='experience__details-icon'/>
              <div>
                <h4>Javascript</h4>
                <small className='text-light'>Experienced</small>
              </div>
            </article>
            <article className='experience__details'>
              <IoMdCheckmarkCircle className='experience__details-icon'/>
              <div>
                <h4>HTML</h4>
                <small className='text-light'>Experienced</small>
              </div>
            </article>
          </div>
        </div>

        {/* this is the financial tools  */}
        <div className="Financial Skills">
          <h3>Financial Tools/Skills</h3>
          <div className="experience__content">
            <article className='experience__details'>
              <IoMdCheckmarkCircle className='experience__details-icon'/>
              <div>
                <h4>Excel/VBA</h4>
                <small className='text-light'>Experienced</small>
              </div>
            </article>
            <article className='experience__details'>
              <IoMdCheckmarkCircle className='experience__details-icon'/>
              <div>
                <h4>Power BI</h4>
                <small className='text-light'>Experienced</small>
              </div>
            </article>
            <article className='experience__details'>
              <IoMdCheckmarkCircle className='experience__details-icon'/>
              <div>
                <h4>Power Automate</h4>
                <small className='text-light'>Experienced</small>
              </div>
            </article>
            <article className='experience__details'>
              <IoMdCheckmarkCircle className='experience__details-icon'/>
              <div>
                <h4>Snowflake</h4>
                <small className='text-light'>Basic</small>
              </div>
            </article>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Experience