import React from 'react'
import './contact.css'
import { MdOutlineMarkEmailUnread } from "react-icons/md";
import { LiaFacebookMessenger } from "react-icons/lia";
import { useRef } from 'react';
import emailjs from '@emailjs/browser';

const Contact = () => {
  /*this part found in the emailjs.com react documentation*/
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_43seorl', 'template_ormavoa', form.current, 'UN5sHXBNt2Lkbyt_v')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });

      e.target.reset()
    };
  /*this sets up the contact area contents*/
  return (
    <section id='contact'>
      <h5>Get In Touch</h5>
      <h2>Contact Me</h2>

      <div className="container contact__container">
        <div className="contact__options">
          {/*this article creates one card for contacts, can add more later*/}
          <article className="contact__option">
            <MdOutlineMarkEmailUnread className='contact__option-icon'/>
            <h4>Email</h4>
            <h5>nikdesh1@gmail.com</h5>
            <a href="mailto:nikhild1@uci.edu">Send a message</a>
          </article>

          <article className="contact__option">
            <LiaFacebookMessenger className='contact__option-icon'/>
            <h4>Messenger</h4>
            <h5>Nikhil Deshpande</h5>
            <a href="https://m.me/nikhildeshpande24/">Send a message</a>
          </article>
        </div>

      {/* End of Contact Options */}
      {/* below is all the form information. the form gets called as a function to the emailJS
      api from the 'const form' made at the top of this const contact method*/}
        <form ref={form} onSubmit={sendEmail}>
            <input type="text" name='name' placeholder='Your Full Name' required/>
            <input type="email" name='email' placeholder='Your Email' required/>
            <textarea name="message" id="7" placeholder='Your Message' required ></textarea>
            <button type='submit' className= 'btn btn-primary'>Send Message</button>
        </form>     
      </div>
    </section>
  )
}
export default Contact