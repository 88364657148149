import React from 'react'
import './nav.css'
import { RiHomeSmile2Line } from "react-icons/ri";
import { LuUserSquare } from "react-icons/lu";
import { BiBookBookmark } from "react-icons/bi";
import { GrBarChart } from "react-icons/gr";
import { TbMessage2Share } from "react-icons/tb";
import {useState} from 'react'
/*this is for setting the contents of the navigation bar at the bottom*/
const Nav = () => {
  /*the activeState allows the section you are on to be transparently
  highlighted in the navbar, we set the active nav to that section
  whenever it is clicked below*/
  const [activeNav, setActiveNav] = useState('#')
  return (
    <nav>
      <a href="#"onClick ={() => setActiveNav('#')} className={activeNav === '#' ? 'active': ''}><RiHomeSmile2Line/></a>
      <a href="#about" onClick ={() => setActiveNav('#about')} className={activeNav === '#about' ? 'active': ''}><LuUserSquare/></a>
      <a href="#experience"onClick ={() => setActiveNav('#experience')} className={activeNav === '#experience' ? 'active': ''}><BiBookBookmark/></a>
      <a href="#contact"onClick ={() => setActiveNav('#contact')} className={activeNav === '#contact' ? 'active': ''}><TbMessage2Share/></a>
    </nav>
  )
}

export default Nav
